.home-content {
    margin-top: -22px;
    min-height: calc(100vh - 100px);
    background: rgb(212,175,104);
    background: linear-gradient(180deg, rgba(212,175,104,1) 70%, rgba(255,255,255,1) 100%);

    h1 {
        background-image: url('../assets/fishers-logo.jpg');
        text-indent: -9999px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 150px;
        background-position: 50%;
    }

    h2 {
        text-align: center;
        font-size: 2rem;
    }

    h3 {
        text-align: center;
        font-size: 1.25rem;
    }

    .button {
        text-align: center;
        display: block;
    }
}


.first-home-image {
    background-image: url('../assets/landscape-large-11.jpg');
    background-size: cover;
    background-position: 100% 100%;
    width: 100%;
    min-height: 300px;
}


@media screen and (min-width: 720px) {
    
    .first-home-image {
        min-height: 600px;
        max-width: 1100px;
        margin:0 auto;
    }

    .home-content {
        h1 {
            min-height: 260px;
        }
    }
}