.services-content {
    margin-top: -22px;
    min-height: calc(100vh - 60px);
    background: url('../assets/grass-bg.jpeg');
    background-size: cover;
    padding-bottom: 50px;

    

    .column {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0,0,0,.25);
        margin: 10px 0;
        padding: 10px;
        background: #fff;


        ul {
            list-style: none;
            padding-inline-start: 0;

            li {
                padding: 10px;
            }
        }
    }

    h1 {
        width: 100%;
        padding-top: 25px;
    }

    h2 {
        text-align: center;
        font-size: 2rem;
    }

    .button {
        text-align: center;
        display: block;
    }
}

@media screen and (min-width: 720px) {
    
    .services-content {
        .services {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

            .column {
                flex-wrap: wrap;
                width: 29%;
                margin: 1%;

                img {
                    width: 100%;
                }
            }
       
        }
        .button {
            margin: 0 auto;
            max-width: 200px;
        }
    }
}