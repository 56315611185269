.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: #282c34;
  position: relative;
  width: 100%;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;

  a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
  }
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}


.nav-elements ul a.active {
  color: #bada55;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ccc;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;

    svg {
      fill: #fff;
    }
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #282c34;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;

    ul {
      display: flex;
      flex-direction: column;

      li {
        margin-right: unset;
        margin-top: 22px;
      }
    }
  }

  .nav-elements.active {
    width: 270px;
    box-shadow: -5px 10px 20px #000;
  }


}