.community-content {
    padding: 50px 15px;
    margin: -22px auto;
    background: url("../assets/ftw-bg.jpeg");
    background-size: cover;
    color: #fff;
    min-height: calc( 100vh - 160px);


    .teams {
        display: flex;
        flex-direction: column;
        max-width: 1100px;
        margin: 0 auto;

        .team-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            img {
                width: 100%;
            }
        }
    }

    h1 {
        text-align: center;
    }
}


@media screen and (min-width: 780px) {
    .community-content {

        .teams {
            flex-direction: row;
            flex-wrap: wrap;

            .team-card {
                flex-direction: column;
                width: 30%;
                margin: 1%;
            }
        }
    }
}