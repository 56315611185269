@media (prefers-reduced-motion: no-preference) {

}

.page-banner {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.content-right-wrapper {
  display:  flex;
  flex-direction: column;
}

.left-image {
  width:  100%;
}

.content-container, .content-wrapper {
  padding: 0 15px;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.button {
  background: #355E3B;
  padding: 15px 30px;
  border-radius: 15px;
  color: #fff;
  transition: .35s ease-in-out;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 1px 1px 5px rgba(0,0,0,.5);

  &:hover {
    background: lighten(#355E3B, 15%);
  }
}

footer {
  background-image: url('../assets/footer.jpg');
  height: 165px;
  margin-top: 50px;
}

@media screen and (min-width: 720px) {
    .content-right-wrapper,
    .content-wrapper {
      flex-direction: row;
      max-width: 1100px;
      margin: 0 auto;
    }


    .left-image {
      width: 50%;
    }

    .content-right {
      width: 50%;
    }
}